import React, { useEffect, useState, useRef } from 'react';
import { axios } from '../../config'
import M from 'materialize-css'
import FacebookConfig from './redes/facebook'
import InstagramConfig from './redes/instagram'
import YoutubeConfig from './redes/youtube'
import JoditEditor from "jodit-react";
import Loading from '../loading'

const MiTienda = () => {

	const [config, setConfig] = useState({production_mode:0,nombre_seccion_principal:'',emailserviciocliente:'',telefonoatencion:'',nombretienda:'',quienessomos:'',img:'', img_promo1:'', img_promo2:'', logo:'', navbackground:'', navtext:'', primarycolor:'', secondarycolor:'', infocolor:'', hovercolor:'', iconcart:'', iconcheck:'', footertext:'', footerbackground:'',icon_facebook:'',url_facebook:'', icon_instagram:'', url_instagram:'', icon_youtube:'',url_youtube:'',visible_promo1:true,visible_promo2:true,configs:{visible_redes:{facebook:1, instagram:1, youtube:1}}})
	const editor = useRef(null)
	const dias = ['Domingo','Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado']
	const r = new RegExp(/^(http|https):\/\/[^ "]+$/);
	const [loader, setLoader] = useState(false)

	
	useEffect(() => {
		
		axios.get('/api/configwebsite')
		.then((r) => {
			setConfig(r.data)
			M.updateTextFields()
			M.FormSelect.init(document.querySelectorAll('select'), {});
		})
		.catch(r=>alert(r))
	},[])

	const addDefaultSrc = (ev) =>{
		ev.target.src = 'https://storage-apis.s3.us-east-2.amazonaws.com/kangoru-shop-api/config/subirImg.jpg'
	}
	const _update = () => {
		let valido = true;
		const  regex = /^(http|https):\/\/[^ "]+$/;
		const {id,production_mode,nombre_seccion_principal,emailserviciocliente, telefonoatencion,nombretienda,quienessomos,img, img_promo1, img_promo2,logo, navbackground, navtext, primarycolor, secondarycolor, infocolor, hovercolor, iconcart, iconcheck, footertext, footerbackground,icon_facebook,url_facebook, icon_instagram, url_instagram, icon_youtube, url_youtube,visible_promo1,visible_promo2, configs} = {...config}
		const conf = {id,production_mode,nombre_seccion_principal, emailserviciocliente, telefonoatencion, nombretienda, quienessomos, img, img_promo1, img_promo2, logo, navbackground, navtext, primarycolor, secondarycolor, infocolor, hovercolor, iconcart, iconcheck, footertext, footerbackground,icon_facebook,url_facebook, icon_instagram, url_instagram, icon_youtube, url_youtube,visible_promo1,visible_promo2, configs}

		setConfig(conf)
		/*console.log('config', config)
		clearTimeout(typing)
		setTyping(setTimeout(() => {
			
		},1000))*/

		if(config.configs.visible_redes.facebook && (!r.test(config.url_facebook) )){
			alert("Verifique que sea correcta la url de la red social facebook."); 
			
		}
		if(config.configs.visible_redes.instagram && (!r.test(config.url_instagram) )){
			alert("Verifique que sea correcta la url de la red social instagram."); 
			
		}
		if(config.configs.visible_redes.youtube && (!r.test(config.url_youtube) )){
			alert("Verifique que sea correcta la url de la red social youtube."); 
			valido=false;
		}

		if(valido){
			setLoader(true)
			const data = new FormData();
			data.append('_method', 'PUT')
			data.append('config', JSON.stringify(conf))
			data.append('img', conf.img)
			data.append('img_promo1', conf.img_promo1)
			data.append('img_promo2', conf.img_promo2)
			
			axios.post('/api/configwebsite', data)
			.then((r) => {
				setConfig(r.data)
				M.toast({html:'Actualizado!'})
				setLoader(false)
			})
			.catch(r=>alert(r))
		}
	}

	const _borrarBanner = (campo)=>{
		if(window.confirm('¿Estas seguro de borrar este banner?')){
			if(campo==='img_promo1')setConfig({...config,img_promo1:''})
			if(campo==='img_promo2')setConfig({...config,img_promo2:''})
			axios.post('/api/borrar_banner',{campo:campo}).then((r)=>{
				M.toast({html:'Imagen Borrada!'})
			}).catch(r=>alert(r))
		}
	}

	if(loader)
		return <Loading />

	return (
		<div className='card-panel'>
			<h4 style={{marginBottom:100, textAlign:'center'}}>Configuraciones de tú tienda online</h4>
			<h6> Todo sus cambios o ajustes se verán reflejados al presionar el botón de <b>guardar</b> en la parte inferior</h6>
			<div className="row">
				<div className='col s12'>
					<h6>Sitio Web: <b>Activar sitio web y mostrarlo online, desactivar para mostrar "sitio en construcción"</b> </h6>
					<div className="switch" style={{margin:'0 auto', textAlign:'center'}}>
						<div className='card padding-20'>
							<label style={{fontWeight:600,}}>
								Desactivado
								<input type="checkbox" checked={config.production_mode} 
								onChange={(e)=>setConfig({...config, production_mode:e.target.checked})} />
								<span className="lever"></span>
								Activo 
							</label>
						</div>
					</div>
				</div>
				
				<h5>Datos de tu e-commerce</h5>
				<div className="input-field col m3">
					<input value={config.nombretienda} onChange={(e)=>setConfig({...config,nombretienda: e.target.value})}  id="nombre_tienda" type="text" className="validate" />
					<label htmlFor="nombre_tienda">Nombre de tu tienda</label>
				</div>
				<div className="input-field col m3">
					<input value={config.telefonoatencion} onChange={(e)=>setConfig({...config,telefonoatencion: e.target.value})}  id="tel_atencion" type="text" className="validate" />
					<label htmlFor="tel_atencion">Teléfono atención</label>
				</div>
				<div className="input-field col m3">
					<input value={config.emailserviciocliente} onChange={(e)=>setConfig({...config,emailserviciocliente: e.target.value})} id="email_servicio" type="text" className="validate" />
					<label htmlFor="email_servicio">E-mail servicio al cliente</label>
				</div>
				<div className="input-field col m3">
					<label htmlFor="section-name">Nombre de menú principal(Ej.Departamentos)</label>
					<input value={config.nombre_seccion_principal} onChange={(e)=>setConfig({...config,nombre_seccion_principal: e.target.value.length<=20?e.target.value:config.nombre_seccion_principal})} id="section-name" type="text" className="validate" />
					<div style={{color:'black', float:'right'}}>{config.nombre_seccion_principal.length}/20</div>
					<div style={{color:'red', fontSize:12}}>Nota: el nombre del menú principal aparece en la barra de navegación principal</div>
				</div>
			</div>
			<div className="row"> Sección quienes somos 
				<div className="input-field col s12">
					
					{/*<JoditEditor ref={editor}  tabIndex={2} onChange={(newContent) =>console.log({...config, quienessomos:newContent})} />*/}
					<JoditEditor value={config.quienessomos} config={{ readonly: false,askBeforePasteHTML:false }}  onBlur={newContent => setConfig({...config, quienessomos:newContent})} />
					
				</div>
			</div>
			<div className="row">
				<h5>Imágenes</h5>
					<div className="col m3">
						<div>Logo</div>
						<p style={{color:'red', fontSize:'12px'}}>Nota: Para una mejor experiencia utiliza una imagen en formato PNG sin fondo</p>
						<label style={{cursor:'pointer',}}>
							<div style={{margin:'0 auto', height:'200px', width:'200px'}}>
								<img alt='img' src={config.img?URL.createObjectURL(config.img):config.logo?config.logo:'https://storage-apis.s3.us-east-2.amazonaws.com/kangoru-shop-api/config/subirImg.jpg'}
								 style={{margin:'0 auto', maxHeight:'100%', maxWidth:'100%', objectFit:'contain',}} />
							</div>
							<input onChange={(e)=>setConfig({...config,img:e.target.files[0]})} type="file" style={{display:'none'}}/>
						</label>
					</div>
				<div className="col m4">
					<div>Icon Cart</div>
					<div className='row' style={{background:'#ECECEC', minHeight:'70px', padding:20, boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)', marginRight:20}}>
						<div className='col'>
							<label style={{height:40, margin:0, padding:'1px'}}>
								<input onChange={(e)=>setConfig({...config,iconcart:'group1'})} name="group" type="radio" id="group1"
								style={{height:'30px', width:'30px',}}
								checked={(config.iconcart==='group1')?true:false}/>
								<span style={{display:'flex', alignItems:'center', margin:5}}>
									<img style={{margin:10,}} alt='img' src='https://storage-apis.s3.us-east-2.amazonaws.com/kangoru-shop-api/config/cart1.png' width='30' height='30'/>
									<img style={{margin:10,}} alt='img' src='https://storage-apis.s3.us-east-2.amazonaws.com/kangoru-shop-api/config/cart2.png' width='30' height='30'/>
									<img style={{margin:10,}} alt='img' src='https://storage-apis.s3.us-east-2.amazonaws.com/kangoru-shop-api/config/cart3.png' width='30' height='30'/>
								</span>
							</label>
							<label style={{height:40, margin:0, padding:'1px'}}>
								<input onChange={(e)=>setConfig({...config,iconcart:'group2'})} name="group" type="radio" id="group2"
								style={{height:'30px', width:'30px', }}
								checked={config.iconcart==='group2'?true:false}/>
								<span style={{display:'flex', alignItems:'center', margin:5}}>
									<img style={{margin:10,}} alt='img' src='https://storage-apis.s3.us-east-2.amazonaws.com/kangoru-shop-api/config/cart4.png' width='30' height='30'/>
									<img style={{margin:10,}} alt='img' src='https://storage-apis.s3.us-east-2.amazonaws.com/kangoru-shop-api/config/cart5.png' width='30' height='30'/>
									<img style={{margin:10,}} alt='img' src='https://storage-apis.s3.us-east-2.amazonaws.com/kangoru-shop-api/config/cart6.png' width='30' height='30'/>
								</span>
							</label>
							<label style={{height:40, margin:0, padding:'1px'}}>
								<input onChange={(e)=>setConfig({...config,iconcart:'group3'})} name="group" type="radio" id="group3"
								style={{height:'30px', width:'30px',}}
								checked={config.iconcart==='group3'?true:false}/>
								<span style={{display:'flex', alignItems:'center', margin:5}}>
									<img style={{margin:10,}} alt='img' src='https://storage-apis.s3.us-east-2.amazonaws.com/kangoru-shop-api/config/cart7.png' width='30' height='30'/>
									<img style={{margin:10,}} alt='img' src='https://storage-apis.s3.us-east-2.amazonaws.com/kangoru-shop-api/config/cart8.png' width='30' height='30'/>
									<img style={{margin:10,}} alt='img' src='https://storage-apis.s3.us-east-2.amazonaws.com/kangoru-shop-api/config/cart9.png' width='30' height='30'/>
								</span>
							</label>
						</div>
					</div>
				</div>
				<div className="col m4">
					<div>Icon Check</div>
					<div className='row' style={{background:'#ECECEC', minHeight:'70px', padding:20, boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)', marginRight:20}}>
						<div className='col'>
							<label style={{height:40, margin:0, padding:'1px'}}>
								<input onChange={(e)=>setConfig({...config,iconcheck:'group1'})} name="group2" type="radio" id="grup1"
								style={{height:'30px', width:'30px',}}
								checked={(config.iconcheck==='group1')?true:false}/>
								<span style={{display:'flex', alignItems:'center', margin:5}}>
									<img style={{margin:10,}} alt='img' src='https://storage-apis.s3.us-east-2.amazonaws.com/kangoru-shop-api/config/check1.png' width='30' height='30'/>
									<img style={{margin:10,}} alt='img' src='https://storage-apis.s3.us-east-2.amazonaws.com/kangoru-shop-api/config/check2.png' width='30' height='30'/>
									<img style={{margin:10,}} alt='img' src='https://storage-apis.s3.us-east-2.amazonaws.com/kangoru-shop-api/config/check3.png' width='30' height='30'/>
								</span>
							</label>
							<label style={{height:40, margin:0, padding:'1px'}}>
								<input onChange={(e)=>setConfig({...config,iconcheck:'group2'})} name="group2" type="radio" id="grup2"
								style={{height:'30px', width:'30px', }}
								checked={config.iconcheck==='group2'?true:false}/>
								<span style={{display:'flex', alignItems:'center', margin:5}}>
									<img style={{margin:10,}} alt='img' src='https://storage-apis.s3.us-east-2.amazonaws.com/kangoru-shop-api/config/check4.png' width='30' height='30'/>
									<img style={{margin:10,}} alt='img' src='https://storage-apis.s3.us-east-2.amazonaws.com/kangoru-shop-api/config/check5.png' width='30' height='30'/>
									<img style={{margin:10,}} alt='img' src='https://storage-apis.s3.us-east-2.amazonaws.com/kangoru-shop-api/config/check6.png' width='30' height='30'/>
								</span>
							</label>
							<label style={{height:40, margin:0, padding:'1px'}}>
								<input onChange={(e)=>setConfig({...config,iconcheck:'group3'})} name="group2" type="radio" id="grup3"
								style={{height:'30px', width:'30px',}}
								checked={config.iconcheck==='group3'?true:false}/>
								<span style={{display:'flex', alignItems:'center', margin:5}}>
									<img style={{margin:10,}} alt='img' src='https://storage-apis.s3.us-east-2.amazonaws.com/kangoru-shop-api/config/check7.png' width='30' height='30'/>
									<img style={{margin:10,}} alt='img' src='https://storage-apis.s3.us-east-2.amazonaws.com/kangoru-shop-api/config/check8.png' width='30' height='30'/>
									<img style={{margin:10,}} alt='img' src='https://storage-apis.s3.us-east-2.amazonaws.com/kangoru-shop-api/config/check9.png' width='30' height='30'/>
								</span>
							</label>
						</div>
					</div>
				</div>
			</div>
			<div className="row">
				<h5>Redes sociales</h5>
				<div className="col m4">
					<div className="switch" style={{margin:'0 auto', textAlign:'center'}}>
						<label style={{fontWeight:600,}}>
							<span  style={{color:'#000000', fontWeight:900, fontSize:'14px'}}> FACEBOOK </span> <br></br> Oculto
							<input type="checkbox" checked={config.configs.visible_redes.facebook} 
							onChange={(e)=>setConfig({...config, configs:{...config.configs, visible_redes:{...config.configs.visible_redes, facebook:e.target.checked}}})} />
							<span className="lever"></span>
							Visible 
						</label><br></br><br></br>
					</div>
					{config.configs.visible_redes.facebook?
						<FacebookConfig setConfig={setConfig} config={config}/>
						:
						<div>&nbsp;</div>
					}
				</div>
				
				<div className="col m4">
					<div className="switch" style={{margin:'0 auto', textAlign:'center'}}>
						<label style={{fontWeight:600,}}>
							<span  style={{color:'#000000', fontWeight:900, fontSize:'14px'}}> INSTAGRAM </span> <br></br> Oculto
							<input type="checkbox" checked={config.configs.visible_redes.instagram} 
							onChange={(e)=>setConfig({...config, configs:{...config.configs, visible_redes:{...config.configs.visible_redes, instagram:e.target.checked}}})} />
							<span className="lever"></span>
							Visible 
						</label><br></br><br></br>
					</div>
					{config.configs.visible_redes.instagram?
						<InstagramConfig setConfig={setConfig} config={config}/>
						:
						<div>&nbsp;</div>
					}
				</div>
				
				<div className="col m4">
					<div className="switch" style={{margin:'0 auto', textAlign:'center'}}>
						<label style={{fontWeight:600,}}>
						<span  style={{color:'#000000', fontWeight:900, fontSize:'14px'}}> YOUTUBE </span> <br></br> Oculto
							<input type="checkbox" checked={config.configs.visible_redes.youtube} 
							onChange={(e)=>setConfig({...config, configs:{...config.configs, visible_redes:{...config.configs.visible_redes, youtube:e.target.checked}}})} />
							<span className="lever"></span>
							Visible 
						</label><br></br><br></br>
					</div>
					{config.configs.visible_redes.youtube?
						<YoutubeConfig setConfig={setConfig} config={config}/>
						:
						<div>&nbsp;</div>
					}
				</div>
				
			</div>
			<div className="row">
				<h5>Colores</h5>
				<div className="col">
					<div>Barra de navegación</div>
					<input value={config.navbackground} onChange={(e)=>setConfig({...config,navbackground:e.target.value})} type="color" style={{width:200, height:40, padding:0,border:0, boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)'}}/>
				</div>
				<div className="col">
					<div>Color texto barra de navegación</div>
					<input value={config.navtext} onChange={(e)=>setConfig({...config,navtext:e.target.value})} type="color" style={{width:200, height:40, padding:0,border:0, boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)'}}/>
				</div>
				<div className="col">
					<div>Color principal</div>
					<input value={config.primarycolor} onChange={(e)=>setConfig({...config, primarycolor:e.target.value})} type="color" style={{width:200, height:40, padding:0,border:0, boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)'}}/>
				</div>
				<div className="col">
					<div>Color secundario</div>
					<input value={config.secondarycolor} onChange={(e)=>setConfig({...config,secondarycolor:e.target.value})} type="color" style={{width:200, height:40, padding:0,border:0, boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)'}}/>
				</div>
				<div className="col">
					<div>Color para textos informativos</div>
					<input value={config.infocolor} onChange={(e)=>setConfig({...config,infocolor:e.target.value})} type="color" style={{width:200, height:40, padding:0,border:0, boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)'}}/>
				</div>
				<div className="col">
					<div> Color de resaltado</div>
					<input value={config.hovercolor} onChange={(e)=>setConfig({...config,hovercolor:e.target.value})} type="color" style={{width:200, height:40, padding:0,border:0, boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)'}}/>
				</div>
				<div className="col">
					<div> Color de fondo del pie de página</div>
					<input value={config.footerbackground} onChange={(e)=>setConfig({...config,footerbackground:e.target.value})} type="color" style={{width:200, height:40, padding:0,border:0, boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)'}}/>
				</div>
				<div className="col">
					<div> Color del texto del pie de página</div>
					<input value={config.footertext} onChange={(e)=>setConfig({...config,footertext:e.target.value})} type="color" style={{width:200, height:40, padding:0,border:0, boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)'}}/>
				</div>
			</div>
			{config.configs.horario&&		
			<div className='row'>
				<h5>Horarios de atención</h5>
				<div className='col s12 padding-20'>
					<div className='card'>
						<div className="input-field col s3">
							<select defaultValue={config.configs.horario.diaInicio||""} onChange={(e)=>setConfig({...config, configs:{...config.configs,horario:{...config.configs.horario,diaInicio:e.target.value}}})}>
								<option value="">Seleccionar un día</option>
								{dias.map((d,i) => <option key={i} value={d}>{d}</option>)}
							</select>
							<label>Día Inicio</label>
						</div>
						<div className="input-field col s3">
							<select defaultValue={config.configs.horario.diaFin||""} onChange={(e)=>setConfig({...config, configs:{...config.configs,horario:{...config.configs.horario,diaFin:e.target.value}}})}>
								<option value="">Seleccionar un día</option>
								{dias.map((d,i) => <option key={i} value={d}>{d}</option>)}
							</select>
							<label>Día Fin</label>
						</div>
			
						<div className="input-field col s2">
							<input id="hora_inicio" 
							type="time" 
							value={config.configs.horario.horaInicio}
							onChange={(e)=>setConfig({...config, configs:{...config.configs,horario:{...config.configs.horario,horaInicio:e.target.value}}})}
							className="validate" />
							
							<label htmlFor="hora_inicio">Hora Inicio</label>
						</div>
						<div className="input-field col s2">
							<input id="hora_fin" 
							type="time" 
							value={config.configs.horario.horaFin}
							onChange={(e)=>setConfig({...config, configs:{...config.configs,horario:{...config.configs.horario,horaFin:e.target.value}}})}
							className="validate" />
							<label htmlFor="hora_fin">Hora Fin</label>
						</div>
					</div>		
				</div>
				
			</div>
			}
			<div className="row">
				<h5>Primera imagen publicitaria</h5>
				<p style={{color:'red', fontSize:'12px'}}>Nota: Tamaño recomendado de las imágenes publicitarias: JPG 1200x600(o imagenes con dimensiones panorámicas)</p>
				<div className="col m8">
					<div>Imagen publicitario 1</div>
					<label style={{cursor:'pointer',}}>
						<div style={{margin:'0 auto', minHeight:'300px', width:'100%'}}>
							<img alt='img' src={config.img_promo1?(typeof(config.img_promo1)==='string'?config.img_promo1:URL.createObjectURL(config.img_promo1)):'https://icon-library.com/images/upload-image-icon-png/upload-image-icon-png-16.jpg'}
							 style={{margin:'0 auto', maxHeight:'100%', maxWidth:'100%', objectFit:'contain',}} />
						</div>
						<input onChange={(e)=>setConfig({...config,img_promo1:e.target.files[0]})} type="file" style={{display:'none'}}/>
					</label>
				</div>
				<div className="col m4" style={{padding:30}}>
					<div style={{textAlign:'center',padding:'20px 10px'}}>
						<i style={{cursor:'pointer',fontSize:40}} className="material-icons red-text" 
							onClick={(e)=>_borrarBanner('img_promo1')}>delete</i>
						<div><label>Borrar imagen </label></div>
					</div>

					<div className="switch" style={{margin:'0 auto', textAlign:'center'}}>
						<label style={{fontWeight:600,}}>
							Oculto
							<input type="checkbox" checked={config.visible_promo1} onChange={(e)=>setConfig({...config, visible_promo1:e.target.checked})}/>
							<span className="lever"></span>
							Visible 
						</label>
					</div>
				</div>
			</div>

			<div className="row">
				<div className="col m8">
					<div>Imagen publicitario 2</div>
					<label style={{cursor:'pointer',}}>
						<div style={{margin:'0 auto', minHeight:'300px', width:'100%'}}>
							<img alt='img' src={config.img_promo2?(typeof(config.img_promo2)==='string'?config.img_promo2:URL.createObjectURL(config.img_promo2)):'https://icon-library.com/images/upload-image-icon-png/upload-image-icon-png-16.jpg'}
							 style={{margin:'0 auto', maxHeight:'100%', maxWidth:'100%', objectFit:'contain',}} />
						</div>
						<input onChange={(e)=>setConfig({...config,img_promo2:e.target.files[0]})} type="file" style={{display:'none'}}/>
					</label>
				</div>
				<div className="col m4" style={{padding:30}}>
					<div style={{textAlign:'center',padding:'20px 10px'}}>
						<i 
							style={{cursor:'pointer',fontSize:40}} 
							className="material-icons red-text"
							onClick={()=>_borrarBanner('img_promo2')}
							
						>delete</i>
						<div><label>Borrar imagen </label></div>
					</div>

					<div className="switch" style={{margin:'0 auto', textAlign:'center'}}>
						<label style={{fontWeight:600,}}>
							Oculto
                        	<input type="checkbox" checked={config.visible_promo2} onChange={(e)=>setConfig({...config, visible_promo2:e.target.checked})}/>
							<span className="lever"></span>
							Visible 
						</label>
					</div>
				</div>
			</div>
			<div className="row">
				<div style={{textAlign:"right"}}>
					<button className="waves-effect waves-light btn btn-flotante pulse" onClick={_update}>Guardar</button>
				</div>
				
			</div>
		</div>
	)
}

export default MiTienda
